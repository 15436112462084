import data from "./zbozi.json";
import { useState } from "react";
function App() {
  const [inputValue, setinputValue] = useState("");
  function inputHandler(e) {
    let val = e.target.value;
    setinputValue(val);
  }

  return (
    <>
      <input
        value={inputValue}
        onChange={inputHandler}
        type="text"
        id="hledej"
      />

      {data
        .filter((el) => {
          return el.KOD === inputValue;
        })
        .map(function (el) {
          return (
            <div key={el.CISLO} className="celek">
              <p>{el.KOD}</p>
              <p>{el.NAZEV}</p>
              <p>{el.CENA_P1_DPH}Kč</p>
            </div>
          );
        })}
    </>
  );
}

export default App;
